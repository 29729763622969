import React, { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  MAC,
  ANC,
  AC,
  AP,
  BS,
  BN,
  BA,
  DB,
  OB,
  WB,
  CO,
  BL,
  BNS,
  BSC,
  LB,
  ROW as ROW_LOCAL,
  ICON as ICON_LOCAL,
  RightArrow,
  ShowText as ShowText_LOCAL,
} from 'UI/apps/MyAccountApp/MyAccount';
import {
  SI,
  UBC,
  SIC,
  ROW as ROW_CORE,
  ICON as ICON_CORE,
  BETCOUNTER,
  ShowText as ShowText_CORE,
} from 'CORE__UI/apps/MyAccountApp/core__myAccount';
import { getComponent } from 'Services/core__imports';
import { redirectUrl } from '../../../core__appconfig.stub';
import accountRedirectUrls from 'Services/global/core__accountRedirectUrls';
import { Tabs } from '../../../../AccountApp/core__AccountInitialState';
import { Translations } from '../../../core__my-account.app';
import { CurrencyFormat } from 'Services/global/core__currency-format';
import { setterGetterFromLocalstorage } from 'Services/localstorage/core__localstorage';
import Constants from '../../../../../../project';
import { detectMob } from '../../../../../core__sportsbook-utils';
import { getFreeSpins } from '../../../core__my-account.store';
import { sumFreeSpins } from '../../../core__my-account-utils';

// Services
import { getIcon, PubSub, PubsubEvents } from 'Services/core__services';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import { BRS } from 'UI/apps/BetslipUKApp/BetslipContainer/RemainingBoosts';
import I from 'UI/globals/Icons';
import { getUserBalance } from 'Services/global/core__manage-user-balance';
const ROW = getComponent(ROW_LOCAL, ROW_CORE);
const ICON = getComponent(ICON_LOCAL, ICON_CORE);
const ShowText = getComponent(ShowText_LOCAL, ShowText_CORE);
/**
 * this component contains the category list and sub categories list
 * @param {*} logout function for logout session of the customer*
 * @param {*} customerDetail object contains the customer details
 */

export const MyAccountPopup = ({
  logout,
  defaultSection,
  showSelfImposingMeasures,
  casinoTheme,
  hiddenTabs,
  kycCheck,
  displayCustomerName,
  customRoutes,
  termsAndGamblingTabs,
  accountOddsBoost,
  showUnsettledBetCount,
  enableArrow,
  showCreditLimit,
  showChatButton,
  closeMyAccountPopUp,
  redirectKYCurl,
  showWithdrawableBalance,
  directRoutes,
  hideActivateAccountOption,
  openQuickDeposit,
  standaloneCustomerBalance,
  displayFreeSpins,
}) => {
  const [freeBetSum, setFreeBetSum] = useState(0);
  const [totalFreeBets, setTotalFreeBets] = useState(0);
  const [isKycApproved, setKycApproved] = useState(false);
  const [showWidthText, handleShowWidthText] = useState(false);
  const [totalFreeSpins, setTotalFreeSpins] = useState(0);
  const {
    customerDetail,
    customerBalance,
    casinoBalance,
    customerCard,
  } = useSelector(state => ({
    customerDetail: state.data.customerDetail,
    customerBalance: state.data.customerBalance,
    casinoBalance: state.data.casinoBalance,
    customerCard: state.data.customerCard,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const token = customerDetail?.raw?.accessToken;

    if (token) {
      dispatch(getFreeSpins(token))
        .then(data => {
          const totalSpins = sumFreeSpins(data);
          setTotalFreeSpins(totalSpins);
        })
        .catch(error => {
          console.error('Error fetching free spins:', error); // eslint-disable-line no-console
        });
    }
  }, [dispatch, customerDetail]);

  const [isCredit, setIsCredit] = useState(false);
  const { wallet = {}, starIconUrl, unsettledBetsCount = 0 } =
    customerDetail || {};
  const iconName = getIcon('PRICE_BOOST');

  const showActivateAccountPopup = () => {
    const session = FSBCustomerSession.getSession();
    if (session) {
      PubSub.emit(PubsubEvents.OPEN_ID_COMPLY, session);
    }
  };
  const validateKyc =
    customerDetail?.kycApproved || customerDetail?.raw?.kycApproved;

  useEffect(() => {
    setKycApproved(
      kycCheck
        ? validateKyc && customerDetail?.raw?.accountStatus === 'ACTIVE'
        : true
    );
    customerDetail?.raw?.maxCredit &&
      setIsCredit(customerDetail?.raw?.creditBalance < 0);
  }, [customerDetail, kycCheck, validateKyc]);

  //calculate sum of credit for total free bets
  useEffect(() => {
    if (
      Object.keys(customerDetail).length > 0 &&
      Object.keys(customerDetail?.wallet).length > 0
    ) {
      let freeBetTotalSum = 0;
      for (const key in wallet) {
        if (wallet[key]['credit'])
          freeBetTotalSum = wallet[key]['credit'] + freeBetTotalSum;
      }
      setFreeBetSum(freeBetTotalSum);
      setTotalFreeBets(Object.keys(customerDetail?.wallet).length);
    }
  }, [wallet]); // eslint-disable-line react-hooks/exhaustive-deps

  const openChat = () => {
    closeMyAccountPopUp();
    PubSub.emit(PubsubEvents.TOGGLE_HELP_BUTTON, { hitting: true });
  };
  const showWithdrawText = status => {
    handleShowWidthText(status);
  };

  return (
    <React.Fragment>
      {customerDetail && (
        <MAC
          casinoTheme={casinoTheme}
          fullHeight={isKycApproved}
          isIphone={'iPhone'.match(detectMob(true))}
        >
          <ANC>
            {customRoutes ? (
              <AC casinoTheme={casinoTheme}>
                {Translations.get('text.wallet')}
              </AC>
            ) : (
              <AC casinoTheme={casinoTheme}>
                {displayCustomerName && customerDetail?.fullName}
              </AC>
            )}

            <AP casinoTheme={casinoTheme}>
              {showCreditLimit && isCredit && '- '}
              {CurrencyFormat(customerDetail?.currencyCode)}
              {standaloneCustomerBalance
                ? parseFloat(customerDetail?.balance?.balance).toFixed(2)
                : getUserBalance(
                    customerBalance,
                    customerDetail,
                    showCreditLimit,
                    showWithdrawableBalance
                  )}
            </AP>
          </ANC>

          {(showWithdrawableBalance && (
            <Fragment>
              <BS extraSpace={true}>
                <UBC>
                  <BN casinoTheme={casinoTheme} showIcon={starIconUrl}>
                    {Translations.get('text.withdrawable.balance')}
                  </BN>
                  &nbsp;
                  <SIC>
                    {starIconUrl && (
                      <SI
                        onClick={() => {
                          showWithdrawText(!showWidthText);
                        }}
                        src={`/images/info-black.svg`}
                      />
                    )}
                  </SIC>
                </UBC>
                <BA casinoTheme={casinoTheme}>
                  {CurrencyFormat(customerDetail?.currencyCode)}
                  {parseFloat(
                    customerDetail?.raw?.withdrawableBalance ||
                      customerDetail?.balance?.withdrawableBalance ||
                      0
                  )?.toFixed(2)}
                </BA>
              </BS>
              {showWidthText && (
                <ShowText>
                  {Translations.get('text.withdrawable.balance.string')}
                </ShowText>
              )}
            </Fragment>
          )) ||
            ''}
          {showCreditLimit && customerDetail?.raw?.maxCredit && (
            <BS>
              <UBC>
                <SIC>{starIconUrl && <SI src={`${starIconUrl}`} />}</SIC>
                <BN casinoTheme={casinoTheme} showIcon={starIconUrl}>
                  {Translations.get('text.credit_limit')}
                </BN>
              </UBC>
              <BA casinoTheme={casinoTheme}>
                {CurrencyFormat(customerDetail?.currencyCode)}
                {customerDetail?.raw?.maxCredit}
              </BA>
            </BS>
          )}
          {(customerDetail?.balance?.sportsbookBalance && (
            <BS>
              <UBC>
                <SIC>{starIconUrl && <SI src={`${starIconUrl}`} />}</SIC>
                <BN
                  casinoTheme={casinoTheme}
                  onClick={() =>
                    defaultSection(Tabs.FREE_BONUS + '|' + Tabs.SPORTSBOOK)
                  }
                  showIcon={starIconUrl}
                >
                  <a href={redirectUrl.SPORTSBOOK}>
                    {Translations.get('text.sportsbook.bonus')}
                  </a>
                </BN>
              </UBC>
              <BA casinoTheme={casinoTheme}>
                {customerDetail?.bplanBalance?.sportsbook?.fbalance}
              </BA>
            </BS>
          )) ||
            null}
          {customerDetail?.wallet && totalFreeBets > 0 && (
            <BS>
              <UBC>
                <SIC>{starIconUrl && <SI src={`${starIconUrl}`} />}</SIC>
                <BN
                  casinoTheme={casinoTheme}
                  onClick={() =>
                    defaultSection(Tabs.FREE_BONUS + '|' + Tabs.FREE)
                  }
                  showIcon={starIconUrl}
                >
                  <a href={accountRedirectUrls.freeBetsAndBonuses}>
                    {`${Translations.get('text.free.bets')} (${totalFreeBets})`}
                  </a>
                </BN>
              </UBC>
              <BA casinoTheme={casinoTheme}>
                {CurrencyFormat(customerDetail?.currencyCode)}
                {freeBetSum.toFixed(2)}
              </BA>
            </BS>
          )}

          {(customerDetail?.balance?.casinoBalance && (
            <BS>
              <UBC>
                <SIC>{starIconUrl && <SI src={`${starIconUrl}`} />}</SIC>
                <BN
                  casinoTheme={casinoTheme}
                  onClick={() =>
                    defaultSection(Tabs.FREE_BONUS + '|' + Tabs.CASINO)
                  }
                  showIcon={starIconUrl}
                >
                  <a href={redirectUrl.CASINO}>
                    {Translations.get('text.casino.bonus')}
                  </a>
                </BN>
              </UBC>
              <BA casinoTheme={casinoTheme}>
                {casinoBalance && CurrencyFormat(customerDetail?.currencyCode)}
                {(casinoBalance && casinoBalance.toFixed(2)) ||
                  customerDetail?.bplanBalance?.casino?.fbalance}
              </BA>
            </BS>
          )) ||
            null}
          {accountOddsBoost &&
            customerDetail?.raw?.availableBetBoostTokens > 0 && (
              <BS>
                <UBC>
                  <SIC>{starIconUrl && <SI src={`${starIconUrl}`} />}</SIC>
                  <BN casinoTheme={casinoTheme} showIcon={starIconUrl}>
                    {Translations.get('text.odds.boost')}
                  </BN>
                </UBC>
                <BRS casinoTheme={casinoTheme}>
                  {customerDetail?.raw?.availableBetBoostTokens}
                  <I iconName={iconName} marginR={8} betBooster={true} />
                </BRS>
              </BS>
            )}
          {displayFreeSpins && (
            <BS>
              <UBC>
                <SIC>{starIconUrl && <SI src={`${starIconUrl}`} />}</SIC>
                <BN casinoTheme={casinoTheme} showIcon={starIconUrl}>
                  {Translations.get('text.free.spins')}
                </BN>
              </UBC>
              <BRS casinoTheme={casinoTheme}>{totalFreeSpins}</BRS>
            </BS>
          )}
          {isKycApproved ? (
            <>
              {openQuickDeposit &&
              customerCard?.some(card => card.available) ? (
                // button opens modal
                <DB
                  onClick={() => {
                    PubSub.emit(PubsubEvents.OPE_QD, null);
                    closeMyAccountPopUp();
                  }}
                  casinoTheme={casinoTheme}
                  quickDeposit={openQuickDeposit}
                >
                  {Translations.get('text.deposit')}
                </DB>
              ) : (
                // button redirects
                <DB
                  onClick={() => {
                    setterGetterFromLocalstorage({
                      keyName: 'tabname',
                      keyValue: 'deposit',
                      action: 'set',
                    });
                  }}
                  casinoTheme={casinoTheme}
                >
                  {directRoutes ? (
                    <a href={Constants.accountRedirectUrls.deposit}>
                      {Translations.get('text.deposit')}
                    </a>
                  ) : customRoutes ? (
                    <a href="/my-account/?tab=banking&deposit=true">
                      {Translations.get('text.deposit')}
                    </a>
                  ) : (
                    <a href={accountRedirectUrls.deposit}>
                      {Translations.get('text.deposit')}
                    </a>
                  )}
                </DB>
              )}

              <OB>
                {showChatButton && (
                  <CO casinoTheme={casinoTheme} onClick={openChat}>
                    {Translations.get('text.lets.chat')}
                  </CO>
                )}
                <WB
                  onClick={() =>
                    setterGetterFromLocalstorage({
                      keyName: 'tabname',
                      keyValue: 'withdraw',
                      action: 'set',
                    })
                  }
                  casinoTheme={casinoTheme}
                >
                  {directRoutes ? (
                    <a href={accountRedirectUrls.withdraw}>
                      {Translations.get('text.withdraw')}
                    </a>
                  ) : customRoutes ? (
                    <a href="/my-account/?tab=banking&withdraw=true">
                      {Translations.get('text.withdraw')}
                    </a>
                  ) : (
                    <a href={accountRedirectUrls.withdraw}>
                      {Translations.get('text.withdraw')}
                    </a>
                  )}
                </WB>
                {!showSelfImposingMeasures && !showChatButton && (
                  <CO
                    casinoTheme={casinoTheme}
                    onClick={() => defaultSection(Tabs.CASHOUT_TAB)}
                  >
                    <a href={accountRedirectUrls.myBets}>
                      {Translations.get('text.cash.out')}
                    </a>
                  </CO>
                )}
              </OB>
              {!customRoutes && <BL />}
              <BSC>
                {hiddenTabs.indexOf(Tabs.BANKING) === -1 && (
                  <a
                    aria-label={Tabs.BANKING}
                    href={accountRedirectUrls.banking}
                  >
                    <ROW>
                      <ICON
                        src={`${Constants.staticPath.basePath}react/olybet/icons/bank.svg`}
                        alt="bank"
                      />
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.BANKING)}
                      >
                        {Translations.get('text.banking')}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </ROW>
                  </a>
                )}
                {hiddenTabs.indexOf(Tabs.MY_BET) === -1 &&
                  !showSelfImposingMeasures && (
                    <a
                      aria-label={Tabs.MY_BET}
                      href={accountRedirectUrls.myBets}
                    >
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.MY_BET)}
                      >
                        {Translations.get('text.my.bets')}
                        {showUnsettledBetCount && (
                          <BETCOUNTER>{unsettledBetsCount}</BETCOUNTER>
                        )}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </a>
                  )}
                {hiddenTabs.indexOf(Tabs.MY_LOTTO_BETS) === -1 &&
                  !showSelfImposingMeasures && (
                    <a
                      aria-label={Tabs.MY_LOTTO_BETS}
                      href={accountRedirectUrls.myLottoBets}
                    >
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.MY_LOTTO_BETS)}
                      >
                        {Translations.get('text.my.lotto.bets')}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </a>
                  )}
                {hiddenTabs.indexOf(Tabs.FREE_BONUS) === -1 && (
                  <a
                    aria-label={Tabs.FREE_BONUS}
                    href={accountRedirectUrls.freeBetsAndBonuses}
                  >
                    <ROW>
                      <ICON
                        src={`${Constants.staticPath.basePath}react/olybet/icons/bonuses.svg`}
                        alt="bonuses"
                      />
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.FREE_BONUS)}
                      >
                        {Translations.get(
                          showSelfImposingMeasures
                            ? 'text.bonuses'
                            : 'text.free.bets.and.bonuses'
                        )}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </ROW>
                  </a>
                )}
                {hiddenTabs.indexOf(Tabs.HISTORY) === -1 && (
                  <a
                    aria-label={Tabs.HISTORY}
                    href={accountRedirectUrls.history}
                  >
                    <ROW>
                      <ICON
                        src={`${Constants.staticPath.basePath}react/olybet/icons/history.svg`}
                        alt="history"
                      />
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.HISTORY)}
                      >
                        {Translations.get('text.history')}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </ROW>
                  </a>
                )}
                {showSelfImposingMeasures ? (
                  <a href={redirectUrl.SIM}>
                    <ROW>
                      <ICON
                        src={`${Constants.staticPath.basePath}react/olybet/icons/self_measures.svg`}
                        alt="measures"
                      />
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.SIM)}
                      >
                        {Translations.get('oly.self.imposing.measures')}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </ROW>
                  </a>
                ) : (
                  hiddenTabs.indexOf(Tabs.GAMBLING) === -1 && (
                    <a
                      aria-label={Tabs.GAMBLING}
                      href={accountRedirectUrls.gamblingControls}
                    >
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.GAMBLING)}
                      >
                        {Translations.get('text.gambling.controls')}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </a>
                  )
                )}
                {hiddenTabs.indexOf(Tabs.ACTIVATE_ACCOUNT) === -1 &&
                  !hideActivateAccountOption &&
                  !validateKyc && (
                    <ROW>
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() =>
                          redirectKYCurl
                            ? defaultSection(Tabs.KYC)
                            : showActivateAccountPopup
                        }
                      >
                        {redirectKYCurl ? (
                          <a href={accountRedirectUrls.kyc}>
                            {Translations.get('text.activate.account')}
                          </a>
                        ) : (
                          Translations.get('text.activate.account')
                        )}

                        {enableArrow && <RightArrow />}
                      </BNS>
                    </ROW>
                  )}
                {hiddenTabs.indexOf(Tabs.KYC) === -1 && (
                  <a aria-label={Tabs.KYC} href={accountRedirectUrls.kyc}>
                    <ROW>
                      <ICON
                        src={`${Constants.staticPath.basePath}react/olybet/icons/kyc.svg`}
                        alt="kyc"
                      />
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.KYC)}
                      >
                        {Translations.get('text.kyc')}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </ROW>
                  </a>
                )}
                {hiddenTabs.indexOf(Tabs.MY_ACCOUNT) === -1 && (
                  <a
                    aria-label={Tabs.MY_ACCOUNT}
                    href={accountRedirectUrls.myAccount}
                  >
                    <ROW>
                      <ICON
                        src={`${Constants.staticPath.basePath}react/olybet/icons/account.svg`}
                        alt="account"
                      />
                      <BNS
                        casinoTheme={casinoTheme}
                        onClick={() => defaultSection(Tabs.ACCOUNT)}
                        lastRow
                      >
                        {Translations.get('text.my.account')}
                        {enableArrow && <RightArrow />}
                      </BNS>
                    </ROW>
                  </a>
                )}

                {termsAndGamblingTabs && (
                  <>
                    {hiddenTabs.indexOf(Tabs.TERMS_AND_CONDITIONS) === -1 &&
                      !showSelfImposingMeasures && (
                        <a
                          aria-label={Tabs.TERMS_AND_CONDITIONS}
                          href={accountRedirectUrls.termsAndConditions}
                        >
                          <BNS
                            casinoTheme={casinoTheme}
                            onClick={() =>
                              defaultSection(Tabs.TERMS_AND_CONDITIONS)
                            }
                          >
                            {Translations.get('text.terms.and.conditions')}
                            {enableArrow && <RightArrow />}
                          </BNS>
                        </a>
                      )}
                    {hiddenTabs.indexOf(Tabs.SAFER_GAMBLING) === -1 &&
                      !showSelfImposingMeasures && (
                        <a
                          aria-label={Tabs.SAFER_GAMBLING}
                          href={accountRedirectUrls.saferGambling}
                        >
                          <BNS
                            casinoTheme={casinoTheme}
                            onClick={() => defaultSection(Tabs.SAFER_GAMBLING)}
                          >
                            {Translations.get('text.safer.gambling')}
                            {enableArrow && <RightArrow />}
                          </BNS>
                        </a>
                      )}
                  </>
                )}
              </BSC>
              {!customRoutes && <BL />}
            </>
          ) : (
            <BSC>
              {!customRoutes && <BL />}
              <a aria-label={Tabs.KYC} href={accountRedirectUrls.kyc}>
                <ROW>
                  <ICON
                    src={`${Constants.staticPath.basePath}react/olybet/icons/kyc.svg`}
                    alt="kyc"
                  />
                  <BNS
                    casinoTheme={casinoTheme}
                    onClick={() => defaultSection(Tabs.KYC)}
                  >
                    {Translations.get('text.kyc')}
                    {enableArrow && <RightArrow />}
                  </BNS>
                </ROW>
              </a>

              {!customRoutes && <BL />}
            </BSC>
          )}
          <LB casinoTheme={casinoTheme} onClick={logout}>
            <ROW logout>
              <ICON
                aria-label={Tabs.LOGOUT}
                src={`${Constants.staticPath.basePath}react/olybet/icons/log_out.svg`}
                alt="logout"
              />
              {Translations.get('text.log.out')}
            </ROW>
          </LB>
        </MAC>
      )}
    </React.Fragment>
  );
};

MyAccountPopup.propTypes = {
  logout: PropTypes.func,
  defaultSection: PropTypes.func,
  casinoBalance: PropTypes.number,
  showSelfImposingMeasures: PropTypes.bool,
  kycCheck: PropTypes.bool,
  casinoTheme: PropTypes.bool,
  hiddenTabs: PropTypes.string,
  displayCustomerName: PropTypes.bool,
  customRoutes: PropTypes.bool,
  termsAndGamblingTabs: PropTypes.bool,
  accountOddsBoost: PropTypes.bool,
  showUnsettledBetCount: PropTypes.number,
  enableArrow: PropTypes.bool,
  showCreditLimit: PropTypes.bool,
  showChatButton: PropTypes.bool,
  closeMyAccountPopUp: PropTypes.func,
  redirectKYCurl: PropTypes.bool,
  showWithdrawableBalance: PropTypes.bool,
  directRoutes: PropTypes.bool,
  hideActivateAccountOption: PropTypes.bool,
  openQuickDeposit: PropTypes.bool,
  standaloneCustomerBalance: PropTypes.bool,
  displayFreeSpins: PropTypes.bool,
};

MyAccountPopup.defaultProps = {
  displayCustomerName: true,
  accountOddsBoost: false,
};

export default MyAccountPopup;
