export const getUserBalance = (
  wsUpdatedBalance,
  customerDetail,
  isCredit,
  showWithdrawableBalance
) => {
  const availableCredit = customerDetail?.raw?.maxCredit;
  const userCashBalance = customerDetail?.raw?.cashBalance;
  const nonCreditUserBalance = customerDetail?.raw?.balance;
  const userCreditBalance = customerDetail?.raw?.creditBalance;
  const withdrawableBalance = customerDetail?.balance?.withdrawableBalance;

  let balance;
  if (isCredit) {
    if (wsUpdatedBalance && availableCredit) {
      if (userCashBalance > 0) {
        balance = (wsUpdatedBalance - availableCredit).toFixed(2);
      } else {
        balance = (availableCredit - wsUpdatedBalance).toFixed(2);
      }
    } else if (wsUpdatedBalance && nonCreditUserBalance >= 0) {
      balance = wsUpdatedBalance.toFixed(2);
    } else if (availableCredit) {
      if (userCreditBalance < 0) {
        balance = userCreditBalance?.toFixed(2).substring(1);
      } else {
        balance = userCashBalance?.toFixed(2);
      }
    } else if (nonCreditUserBalance >= 0) {
      balance = nonCreditUserBalance?.toFixed(2);
    } else {
      balance = userCreditBalance?.toFixed(2).substring(1);
    }
  } else {
    if (wsUpdatedBalance) {
      balance = wsUpdatedBalance
        ? wsUpdatedBalance.toFixed(2)
        : userCashBalance > 0
        ? userCashBalance?.toFixed(2)
        : (nonCreditUserBalance - availableCredit)?.toFixed(2);
    } else {
      balance = nonCreditUserBalance.toFixed(2);
    }
    if (showWithdrawableBalance && withdrawableBalance >= 0) {
      balance = parseFloat(
        Math.max(0, parseFloat(balance)) +
          Math.max(0, parseFloat(withdrawableBalance))
      )?.toFixed(2);
    }
  }
  return balance;
};
