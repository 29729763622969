import {
  MAC,
  ANC,
  AC,
  AP,
  BS,
  BN,
  BA,
  DB,
  OB,
  WB,
  CO,
  BL,
  BNS,
  BSC,
  LB,
  RightArrow,
  ShowText,
} from 'CORE__UI/apps/MyAccountApp/core__myAccount';
import { GREYS, BK_COLORS, BRAND, INFO_BOX_BG } from '../../globals/colours';
import styled, { css } from 'styled-components';
const AC_EX = styled(AC)`
  ${({ casinoTheme }) =>
    casinoTheme &&
    css`
      color: ${BRAND.primary};
    `}
`;
const AP_EX = styled(AP)`
  ${({ casinoTheme }) =>
    casinoTheme &&
    css`
      color: ${BRAND.primary};
    `}
`;
const MAC_EX = styled(MAC)`
  width: 300px;
  padding: 0;
  ${({ casinoTheme }) =>
    casinoTheme &&
    css`
      background-color: ${GREYS.white};
    `}
`;
const DB_EX = styled(DB)`
  border-radius: 20px;
  height: 40px;
  color: ${GREYS.white};
  background-color: ${BK_COLORS.red};
  border-color: ${BK_COLORS.red};
  text-transform: uppercase;
  margin: 8px 16px;
`;
const WB_EX = styled(WB)`
  border-radius: 20px;
  height: 40px;
  color: ${GREYS.black};
  background-color: ${GREYS.white};
  border-color: ${BK_COLORS.aliceBlue10};
  text-transform: uppercase;
`;
const CO_EX = styled(CO)`
  border-radius: 20px;
  height: 40px;
  color: ${GREYS.black};
  background-color: ${GREYS.white};
  border-color: ${BK_COLORS.aliceBlue10};
  text-transform: uppercase;
`;
const BNS_EX = styled(BNS)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${GREYS.dove};
  padding: 16px 16px;
  margin: 0px;
  ${({ lastRow }) =>
    lastRow &&
    css`
      border-bottom: 0;
    `};
  ${({ casinoTheme }) =>
    casinoTheme &&
    css`
      color: ${GREYS.black};
    `};
`;
const LB_EX = styled(LB)`
  height: 40px;
  color: ${GREYS.black};
  background-color: ${GREYS.white};
  border: 1px solid ${BK_COLORS.aliceBlue10};
  border-radius: 20px;
  margin: 12px 16px 16px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BN_EX = styled(BN)`
  ${({ casinoTheme }) =>
    casinoTheme &&
    css`
      color: ${GREYS.black};
    `}
`;

const BA_EX = styled(BA)`
  ${({ casinoTheme }) =>
    casinoTheme &&
    css`
      color: ${GREYS.black};
    `}
`;

const BL_EX = styled(BL)`
  background-color: ${GREYS.white};
  margin: 0;
`;

const ANC_EX = styled(ANC)`
  margin: 16px;
`;

const BS_EX = styled(BS)`
  margin: 4px 16px;

  ${({ extraSpace }) =>
    extraSpace &&
    css`
      margin: 12px 16px;
    `}
`;

const OB_EX = styled(OB)`
  margin: 0px 16px;
`;

const BSC_EX = styled(BSC)`
  margin-bottom: 0;
`;
const ShowText_EX = styled(ShowText)`
  background: ${INFO_BOX_BG};
`;

export {
  MAC_EX as MAC,
  ANC_EX as ANC,
  AC_EX as AC,
  AP_EX as AP,
  BS_EX as BS,
  BN_EX as BN,
  BA_EX as BA,
  DB_EX as DB,
  OB_EX as OB,
  WB_EX as WB,
  CO_EX as CO,
  BL_EX as BL,
  BNS_EX as BNS,
  BSC_EX as BSC,
  LB_EX as LB,
  RightArrow,
  ShowText_EX as ShowText,
};
