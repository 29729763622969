export const sumFreeSpins = freeSpinsData => {
  if (
    !freeSpinsData?.freeRoundsDetails ||
    !Array.isArray(freeSpinsData.freeRoundsDetails)
  ) {
    return 0;
  }

  const totalSpins = freeSpinsData.freeRoundsDetails.reduce((total, item) => {
    return total + (item.remainingRounds || 0);
  }, 0);

  return totalSpins;
};
